import { useMutation } from "react-query";
import { BASE_URL } from "../api";
import { AverageServiceTimePerUnitTypePayload, AverageServiceTimePerUnitTypeResponse } from "../api/types";
import { convertResponseToAverageServiceTimePerUnitTypeData } from "../pages/dashboard/utils";

export const fetchAverageServiceTimePerUnitTypeData = async (
  data: AverageServiceTimePerUnitTypePayload
): Promise<AverageServiceTimePerUnitTypeResponse> => {
  const res = await fetch(`${BASE_URL}/MaintenanceBay/AverageServiceTimePerUnitType`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res.json();
};

export const useAverageServiceTimePerUnitType = () => {
  const { mutate, data, isLoading, error } = useMutation(
    fetchAverageServiceTimePerUnitTypeData
  );

  const newData = convertResponseToAverageServiceTimePerUnitTypeData(
    data as AverageServiceTimePerUnitTypeResponse
  );

  return { mutate, data: newData, isLoading, error };
};
