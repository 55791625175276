import { useMutation } from "react-query";
import { BASE_URL } from "../api";
import { IdleTimePayload, IdleTimeResponse } from "../api/types";
import { convertResponseIdleTimeData } from "../pages/dashboard/utils";

export const fetchIdleTimeData = async (
  data: IdleTimePayload
): Promise<IdleTimeResponse> => {
  const res = await fetch(`${BASE_URL}/MaintenanceBay/IdleTime`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res.json();
};

export const useIdleTime = () => {
  const { mutate, data, isLoading, error } = useMutation(
    fetchIdleTimeData
  );

  const newData = convertResponseIdleTimeData(
    data as IdleTimeResponse
  );

  return { mutate, data: newData, isLoading, error };
};
