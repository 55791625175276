import { useEffect, useState } from "react";
import { PageContent } from "../styled";
import { LiveViewPageBody } from "./components/LiveViewPageBody";
import { LiveViewPageHeader } from "./components/LiveViewPageHeader";
import { useBays } from "../../hooks/bays-hook";
import { Bay } from "../../api/types";
import { StyledAlert } from "../../styled";
import { convertBaysToSearchableListItems } from "../vehicles/utils";
import useDevice from "../../hooks/useDevice";
import { LeftOutlined } from "@ant-design/icons";
import { LogoutButton, Styled3dViewModal } from "./styled";
import { useTrackOffset } from "../../hooks/trackOffsetHook";

export const LiveViewPage = () => {
  const { data: baysData, isLoading, error, refetch: refetchBays } = useBays();
  const { isMobileView } = useDevice();
  const { containerRef } = useTrackOffset();
  const [bays, setBays] = useState(baysData);
  console.log('bays:', bays)
  const [selectedBay, setSelectedBay] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setBays(baysData);
  }, [baysData]);

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      refetchBays();
    }, 30000);

    return () => {
      clearInterval(refetchInterval);
    };
  }, [refetchBays]);

  const activeBays = bays?.filter((bay) => bay.isActive).length;

  const onSearch = (value: string) => {
    setBays(baysData?.filter((item) => item.lane.toString().includes(value)));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleBaySelection = (value: number) => {
    setSelectedBay(value);
  };

  const logout = () => {
    localStorage.removeItem("auth");
    window.location.reload();
  };

  console.log('convertBaysToSearchableListItems(bays as Bay[]):', convertBaysToSearchableListItems(bays as Bay[]))

  return (
    <PageContent ref={containerRef} isMobileView={isMobileView}>
      {isMobileView && (
        <LogoutButton to="/login" onClick={logout}>
          <LeftOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          Logout
        </LogoutButton>
      )}
      {!!error && (
        <StyledAlert type="error" closable message="Something went wrong!" />
      )}

      <LiveViewPageHeader
        totalBays={baysData?.length as number}
        showModal={showModal}
        activeBays={activeBays as number}
      />
      <LiveViewPageBody
        bays={convertBaysToSearchableListItems(bays as Bay[])}
        selectedBay={selectedBay}
        handleItemSelection={handleBaySelection}
        onSearch={onSearch}
        loading={isLoading}
      />

      <Styled3dViewModal
        title={`Bays 3D View`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1015}
      >
        <iframe
          src="https://propetro.nswd.eu/3DView/"
          title="3D view"
          width={"100%"}
          height={590}
        ></iframe>
      </Styled3dViewModal>
    </PageContent>
  );
};
