import { useMutation } from "react-query";
import { BASE_URL } from "../api";
import { BayUtilizationPayload } from "../api/types";

export type Occupancy = {
  from: number; // assuming 'from' and 'to' are timestamps in seconds
  to: number;
};

export type BayOccupancyResponse = {
  bayId: number;
  occupancies: Occupancy[];
};

export const fetchBayUtilizationData = async (
  data: BayUtilizationPayload
): Promise<BayOccupancyResponse> => {
  const res = await fetch(`${BASE_URL}/MaintenanceBay/BayUtiilizationHeatmap`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
  
  return res.json();
};

export const useBayUtilizationHeatmap = () => {
  const { mutate, data, isLoading, error } = useMutation(
    fetchBayUtilizationData
  );

  return { mutate, data, isLoading, error };
};
