import { Select, Modal, Button, Form, Typography } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  FiltersCol,
  FiltersContainer,
  FiltersFormItem,
  SelectedDateWrapper,
  StyledDivider,
} from "../../styled";
import { bayUtilizationChartMockData as data } from "./mock/chartsMock";
import { FormFooter } from "../../../vehicles/styled";
import { useBayUtilizationHeatmap } from "../../../../hooks/bayUtilizationHeatmapHook";
import { StyledSpin } from "../../../../styled";
import { CustomRangePicker } from "../CustomRangePicker";
import HeatmapChart from "./HeatmapChart";
import { dayjsCST } from "../../../../utils/dayjsConfig";

const { Option } = Select;

interface OwnProps {
  chartHeight: number;
  filtersSubmitted: boolean;
  setFiltersSubmitted: Dispatch<SetStateAction<boolean>>;
}

export const BayUtilizationHeatmapChart = ({
  chartHeight,
  filtersSubmitted,
  setFiltersSubmitted,
}: OwnProps) => {
  const [bays, setBays] = useState(data);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const { mutate, data: bayUtilizationHeatmapData, isLoading } = useBayUtilizationHeatmap();
  const [startDate, setStartDate] = useState(
    dayjsCST().add(0, "day").startOf("d").format(),
  );
  const [endDate, setEndDate] = useState(dayjsCST().format());
  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjsCST().add(0, "day").startOf("d").unix(),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(dayjsCST().endOf("d").unix());

  useEffect(() => {
    mutate({
      bays: bays.map((bay) => bay.bayValue),
      fromInUnixSeconds: selectedStartDate,
      toInUnixSeconds: selectedEndDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: string[]) => {
    if (value.includes("all")) {
      setBays(data);
      return;
    }
    setBays(data.filter((item) => value.includes(item.label)));
  };

  useEffect(() => {
    setBays(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filtersSubmitted === true) {
      mutate({
        bays: bays.map((bay) => bay.bayValue),
        fromInUnixSeconds: selectedStartDate,
        toInUnixSeconds: selectedEndDate,
      });
      setFiltersSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSubmitted])

  const openFiltersModal = () => {
    setFiltersModalOpen(true);
  };

  const closeFiltersModal = () => {
    setFiltersModalOpen(false);
  };

  const onFiltersSubmit = () => {
    setFiltersSubmitted(true)
    closeFiltersModal();
  };

  return (
    <>
      <FiltersContainer>
        <Modal
          title="Filters"
          open={filtersModalOpen}
          onCancel={closeFiltersModal}
          footer={null}
          destroyOnClose
        // afterOpenChange={fillFormData}
        // afterClose={resetFormAndData}
        >
          <Form layout="vertical" onFinish={onFiltersSubmit}>
            <FiltersFormItem label="Select bays">
              <Select
                style={{ width: "100%" }}
                placeholder="Please select bays"
                onChange={handleChange}
                mode="multiple"
                allowClear
                onClear={() => setBays([])}
                value={bays.map((bay) => bay.value)}
              >
                <Option key="all" value="all">
                  Select All
                </Option>
                {data.map((option: any) => (
                  <Option key={option.value} value={option.value}>
                    {option.value}
                  </Option>
                ))}
              </Select>
            </FiltersFormItem>
            <FiltersFormItem label="Select date range">
              <CustomRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
                restrictToOneDay
              />
            </FiltersFormItem>
            <FormFooter>
              <Button type="ghost" onClick={closeFiltersModal}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </FormFooter>
          </Form>
        </Modal>
        <FiltersCol>
          <Typography.Title style={{ margin: 0 }} level={3}>
            Bay Utilization Heatmap
          </Typography.Title>
        </FiltersCol>
        <FiltersCol>
          <Button
            onClick={openFiltersModal}
            style={{ marginLeft: "auto", display: "block" }}
          >
            Filters
          </Button>
        </FiltersCol>
      </FiltersContainer>
      <SelectedDateWrapper>
        <span>
          {dayjsCST(startDate).format("MMM DD, YYYY")}
        </span>
      </SelectedDateWrapper>
      <StyledDivider />
      {isLoading && <StyledSpin />}
      {!isLoading && bayUtilizationHeatmapData && Array.isArray(bayUtilizationHeatmapData) && (
        <div style={{ paddingTop: '20px', overflowX: 'auto' }}>
          <HeatmapChart
            chartHeight={chartHeight}
            bayUtilizationHeatmapData={bayUtilizationHeatmapData}
            bays={bays}
            selectedStartDate={selectedStartDate}
          />
        </div>
      )}
    </>
  );
};
