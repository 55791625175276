import { useMutation } from "react-query";
import { BASE_URL } from "../api";
import { ThroughputPerBayPayload, ThroughputPerBayResponse } from "../api/types";
import { convertResponseToThroughputPerBayData } from "../pages/dashboard/utils";

export const fetchThroughputPerBayData = async (
  data: ThroughputPerBayPayload
): Promise<ThroughputPerBayResponse> => {
  const res = await fetch(`${BASE_URL}/MaintenanceBay/ThroughputPerBay`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res.json();
};

export const useThroughputPerBay = () => {
  const { mutate, data, isLoading, error } = useMutation(
    fetchThroughputPerBayData
  );

  const newData = convertResponseToThroughputPerBayData(
    data as ThroughputPerBayResponse
  );

  return { mutate, data: newData, isLoading, error };
};
