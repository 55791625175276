import { SelectProps } from "antd";
import {
  Bay,
  MaintenanceBay,
  MaintenanceBayTableData,
  SearchableListItemType,
  Vehicle,
  VehicleTableData,
  VehicleType,
} from "../../api/types";

export const convertVehiclesToTableData = (
  vehicles: Vehicle[]
): VehicleTableData[] => {
  return vehicles?.map((vehicle) => ({ ...vehicle, key: vehicle.id }));
};

export const convertVehicleTypesToSelectOptions = (
  vehicleTypes: VehicleType[]
) => {
  return vehicleTypes.reduce(
    (acc: SelectProps["options"], cur: VehicleType) => {
      const selectOptionItem = {
        label: cur.vehicleName === "Semi Truck" ? "Tractor" : cur.vehicleName,
        value: cur.vehicleTypeId,
      };
      acc?.push(selectOptionItem);
      return acc;
    },
    []
  );
};


export const convertVehiclesToSearchableListItems = (
  vehicles: Vehicle[]
): SearchableListItemType[] => {
  return vehicles?.reduce(
    (acc: SearchableListItemType[], cur: Vehicle, index: number) => {
      const item = {
        key: index + 1,
        isActive: cur.active,
        vehicles: [],
        location: "Maintenance bay 14",
        vehicleType: cur.vehicleType,
        maintenanceType: "Cooling system",
        percentage: 94,
        productionYear: cur.productionYear,
      };
      acc?.push(item);
      return acc;
    },
    []
  );
};

export const convertMaintenanceBaysToTableData = (
  maintenanceBays: MaintenanceBay[]
): MaintenanceBayTableData[] => {
  return maintenanceBays?.map((bay) => ({ ...bay, key: bay.id }));
};

export const convertBaysToSearchableListItems = (
  bays: Bay[]
): SearchableListItemType[] => {
  return bays?.reduce((acc: SearchableListItemType[], cur: Bay) => {
    const updatedVehicles = cur.vehicles?.map(vehicle => {
      // Update the type if it's "Semi-Truck"
      if (vehicle.type === "Semi-Truck") {
        return { ...vehicle, type: "Tractor" };
      }
      return vehicle;
    });

    const item = {
      key: cur.lane,
      isActive: cur.isActive,
      vehicles: updatedVehicles,
    };

    acc?.push(item);
    return acc;
  }, []);
};
