import {
  BayInfoCard,
  BayInfoContainer,
  CardBody,
  CardHeader,
} from "../styled";
import { Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { VehicleInfoItem } from "./VehicleInfoItem";
import { SearchableListItemType } from "../../../api/types";
import useDevice from "../../../hooks/useDevice";

interface OwnProps {
  items: SearchableListItemType[];
  selectedItem: number;
  handleItemSelection: (value: number) => void;
  itemPrefix: string;
}

export const ItemInfo = ({
  items,
  selectedItem,
  handleItemSelection,
  itemPrefix,
}: OwnProps) => {
  const { isMobileView } = useDevice();
  const item = items?.find((bay) => bay.key === selectedItem);

  if (selectedItem === 0) return null;

  return (
    <BayInfoContainer isMobileView={isMobileView}>
      <BayInfoCard isMobileView={isMobileView}>
        <CardHeader isMobileView={isMobileView}>
          <Typography.Title level={4} style={{ margin: 0, fontWeight: "bold" }}>
            {`${itemPrefix} ${item?.key !== undefined ? (item.key < 10 ? '0' + item.key : item.key) : 'N/A'}`}
          </Typography.Title>
          <CloseOutlined
            style={{fontSize: "20px"}}
            onClick={() => handleItemSelection(0)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
        </CardHeader>
        <CardBody>
          {item?.isActive === false ? (
            <Typography.Paragraph
              style={{
                margin: 0,
                fontSize: 16,
                fontWeight: "bold",
                padding: "20px 10px 10px",
              }}
            >
              Offline
            </Typography.Paragraph>
          ) : item?.vehicles?.length === 0 ? (
            <Typography.Paragraph
              style={{
                margin: 0,
                fontSize: 16,
                fontWeight: "bold",
                padding: "20px 10px 0",
              }}
            >
              Empty
            </Typography.Paragraph>
          ) : (
            item?.vehicles?.map((vehicle) => (
              <VehicleInfoItem key={vehicle.vehicleId} vehicle={vehicle} />
            ))
          )}
        </CardBody>
      </BayInfoCard>
    </BayInfoContainer>
  );
};
