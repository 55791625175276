import { blue, red } from "@ant-design/colors";
import styled from "styled-components";

export const BayGrid = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: crosshair;
`;

export const TimeLabelsRow = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const EmptyLabel = styled.div`
  width: 50px; /* Matches the width of bay-label, adjust if needed */
`;

export const TimeLabelsWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const HourLabel = styled.div`
  flex: 1;
  text-align: center;
  font-size: 10px;
  color: #333;
`;

export const BayRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  border-right: 1px solid #e0e0e0;
`;

export const BayLabel = styled.div`
  width: 50px; /* Adjust the width as necessary */
  text-align: left;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const HourColumnsWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const HourColumn = styled.div`
  flex: 1;
  border-left: 1px solid #e0e0e0;
  height: 100%;
`;

export const TimeSpan = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: ${blue.primary};
  opacity: 0.7;
  border-radius: 3px;
`;

// Vertical line for hover
export const HoverLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: ${red.primary};
  z-index: 1;
  pointer-events: none; /* Ignore pointer events */
`;

// Tooltip to display time on hover
export const Tooltip = styled.div`
  position: absolute;
  top: -20px;
  padding: 2px 5px;
  background-color: black;
  color: white;
  font-size: 10px;
  border-radius: 3px;
  white-space: nowrap;
  z-index: 1000;
  transform: translateX(-50%);
  pointer-events: none; /* Ignore pointer events */
`;


// Vertical line for the current time ("Now")
export const NowLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: ${red.primary};
  z-index: 1;
  pointer-events: none; /* Ignore pointer events */
`;

// Tooltip to display "Now" above the current time line
export const NowTooltip = styled.div`
  position: absolute;
  top: -20px;
  padding: 2px 5px;
  background-color: ${red.primary};
  color: white;
  font-size: 10px;
  border-radius: 3px;
  white-space: nowrap;
  z-index: 999;
  transform: translateX(-50%);
  pointer-events: none; /* Ignore pointer events */
`;
