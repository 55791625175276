import React, { useState, useEffect, useRef } from 'react';
import { BayOccupancyResponse } from '../../../../hooks/bayUtilizationHeatmapHook';
import dayjs from 'dayjs';
import { BayGrid, BayLabel, BayRow, EmptyLabel, HourColumn, HourColumnsWrapper, HourLabel, HoverLine, NowLine, TimeLabelsRow, TimeLabelsWrapper, TimeSpan, Tooltip, NowTooltip } from './styled/HeatmapChart';
import { dayjsCST } from '../../../../utils/dayjsConfig';

interface OwnProps {
  bayUtilizationHeatmapData: BayOccupancyResponse[];
  chartHeight: number;
  bays: { label: string; value: string; bayValue: number; }[];
  selectedStartDate: number;
}

const convertToHour = (timestamp: number | null): number | null => {
  if (timestamp === null) return null;
  const time = dayjsCST(dayjs.unix(timestamp));
  return time.hour() + time.minute() / 60; // Returns hour with fractional minutes
};

const transformDataForChart = (bayUtilizationHeatmapData: BayOccupancyResponse[]) => {
  const chartData: { bayId: number, fromHour: number, toHour: number }[] = [];

  bayUtilizationHeatmapData.forEach(bay => {
    bay.occupancies.forEach(occupancy => {
      const fromHour = convertToHour(occupancy.from);
      const toHour = convertToHour(occupancy.to);

      if (fromHour !== null && toHour !== null) {
        chartData.push({
          bayId: bay.bayId,
          fromHour,  // Start of occupancy in hours
          toHour,    // End of occupancy in hours
        });
      }
    });
  });

  return chartData;
};

const hoursInDay = 24;

const HeatmapChart = ({ chartHeight, bayUtilizationHeatmapData, bays, selectedStartDate }: OwnProps) => {
  const bayData = transformDataForChart(bayUtilizationHeatmapData);
  const [hoverPosition, setHoverPosition] = useState<number | null>(null);
  const [hoverTime, setHoverTime] = useState<string | null>(null);
  const [currentTimePosition, setCurrentTimePosition] = useState<number | null>(null);
  const bayLabelWidth = 50;

  const bayRowHeight = chartHeight / bays.length;
  const bayGridRef = useRef<HTMLDivElement | null>(null); // Create a ref for BayGrid

  const calculateCurrentTimePosition = () => {
    const newDate = dayjsCST();
    const currentHour = newDate.hour() + newDate.minute() / 60;
    
    // Get the width of the BayGrid
    const bayGridWidth = bayGridRef.current?.getBoundingClientRect().width || window.innerWidth;

    // Calculate the usable chart width using the BayGrid width
    const usableChartWidthPercentage = 100 - (bayLabelWidth / bayGridWidth) * 100;
    const currentTimePosition = (currentHour / hoursInDay) * usableChartWidthPercentage + (bayLabelWidth / bayGridWidth * 100);
    
    setCurrentTimePosition(currentTimePosition);
  };

  useEffect(() => {
    // Calculate the current time's position in the chart
    calculateCurrentTimePosition();

    // Add resize event listener
    const handleResize = () => {
      calculateCurrentTimePosition();
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [bays.length, chartHeight]); // Add dependencies to the useEffect

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const bayGrid = event.currentTarget;
  
    const { width } = bayGrid.getBoundingClientRect();
    const relativeX = event.clientX - bayGrid.getBoundingClientRect().left - bayLabelWidth;
  
    if (relativeX < 0) return; // Prevent negative values if hovering over the label section
    
    const usableWidth = width - bayLabelWidth; // Subtract the label width and padding from total grid width
    const hour = (relativeX / usableWidth) * hoursInDay; // Convert to hours
  
    const minutes = Math.floor((hour - Math.floor(hour)) * 60); // Get the minute within the hour
    const snappedHour = Math.floor(hour);
    const snappedTime = dayjsCST().startOf('day').add(snappedHour, 'hour').add(minutes, 'minute').format('HH:mm');
  
    setHoverPosition(relativeX + bayLabelWidth); // Adjust hover position for the vertical line
    setHoverTime(snappedTime);
  };

  const handleMouseLeave = () => {
    setHoverPosition(null);
    setHoverTime(null);
  };

  const renderBayRows = () => {
    return bays.map(bay => (
      <BayRow key={bay.bayValue} style={{ height: `${bayRowHeight}px` }}>
        <BayLabel>{bay.label}</BayLabel>
        <HourColumnsWrapper>
          {renderHourColumns()}
          {renderRectangles(bay.bayValue)}
        </HourColumnsWrapper>
      </BayRow>
    ));
  };

  const renderHourColumns = () => {
    return Array.from({ length: hoursInDay }).map((_, hour) => (
      <HourColumn key={hour} />
    ));
  };

  const renderTimeLabels = () => {
    return Array.from({ length: hoursInDay }).map((_, hour) => {
      const formattedTime = dayjsCST().startOf('day').add(hour, 'hour').format('HH:mm');
      return (
        <HourLabel key={hour}>
          {formattedTime}
        </HourLabel>
      );
    });
  };

  const renderRectangles = (bayId: number) => {
    return bayData
      .filter(data => data.bayId === bayId)
      .map((data, index) => {
        const fromPosition = (data.fromHour / hoursInDay) * 100;
        const spanWidth = ((data.toHour - data.fromHour) / hoursInDay) * 100;

        return (
          <TimeSpan
            key={index}
            style={{
              left: `${fromPosition}%`,
              width: `${spanWidth}%`
            }}
          />
        );
      });
  };

  const isToday = dayjsCST(selectedStartDate * 1000).isSame(dayjsCST(), 'day'); 

  return (
    <BayGrid
      ref={bayGridRef} 
      style={{ height: chartHeight }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {renderBayRows()}
      <TimeLabelsRow>
        <EmptyLabel />
        <TimeLabelsWrapper>
          {renderTimeLabels()}
        </TimeLabelsWrapper>
      </TimeLabelsRow>

      {/* Render the vertical line and tooltip */}
      {hoverPosition !== null && (
        <>
          <HoverLine style={{ left: `${hoverPosition}px` }} />
          <Tooltip style={{ left: `${hoverPosition}px` }}>
            {hoverTime}
          </Tooltip>
        </>
      )}

      {/* Render the current time line and "Now" tooltip */}
      {isToday && currentTimePosition !== null && (
        <>
          <NowLine style={{ left: `${currentTimePosition}%` }} />
          <NowTooltip style={{ left: `${currentTimePosition}%` }}>Now - {`${dayjsCST().format('HH:mm')}`}</NowTooltip>
        </>
      )}
    </BayGrid>
  );
};

export default HeatmapChart;
