import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Set the CST timezone (America/Chicago)
const CST = 'America/Chicago';

// Wrapper function to enforce CST globally
export const dayjsCST = (date?: dayjs.ConfigType) => {
  return dayjs(date).tz(CST);
};
