import { useMutation } from "react-query";
import { BASE_URL } from "../api";
import { UnitTypeDemandPayload, UnitTypeDemandResponse } from "../api/types";
import { convertResponseToUnitTypeDemandData } from "../pages/dashboard/utils";

export const fetchUnitTypeDemandData = async (
  data: UnitTypeDemandPayload
): Promise<UnitTypeDemandResponse> => {
  const res = await fetch(`${BASE_URL}/MaintenanceBay/UnitTypeDemand`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res.json();
};

export const useUnitTypeDemand = () => {
  const { mutate, data, isLoading, error } = useMutation(
    fetchUnitTypeDemandData
  );

  const newData = convertResponseToUnitTypeDemandData(
    data as UnitTypeDemandResponse
  );

  return { mutate, data: newData, isLoading, error };
};
